// Import Functions.
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";

function Navbar() {
  // Accessing data.
  const { data } = useSelector((state) => state.cms);
  const singleTypeSlug = "navbar";
  const navData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // Display component if data has been loaded.
  if (!navData) return null;
  let numLinks = navData?.links?.length;
  let fontSize = 16;
  if (numLinks > 5) {
    fontSize = 16 - (numLinks - 3);
  }

  return (
    <div
      data-animation="default"
      data-collapse="medium"
      data-duration={400}
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      className="navbar w-nav"
    >
      <div className="w-container">
        {/* Logo */}
        <Link to="/" aria-current="page" className="w-nav-brand">
          <img
            src={navData.logo.data && navData.logo.data.attributes.url}
            loading="lazy"
            alt=""
            className="logo"
          />
        </Link>

        <nav role="navigation" className="navmenu w-nav-menu">
          {/* Display of Nav Links */}
          {navData.links &&
            navData.links.map((item) => {
              // Determine if the item will be a dropdown or not.
              return item.subLinks && item.subLinks.length > 0 ? (
                <NavbarDropdown key={uuid()} item={item} fontSize={fontSize} />
              ) : (
                <NavbarLink key={uuid()} item={item} fontSize={fontSize} />
              );
            })}

          {/* Highlighted Component */}
          <div className="divnavlinkhighlighted">
            <Link
              to={navData.slugHighlight}
              className="linkblockhighlighted w-inline-block"
            >
              <div className="embedjoin w-embed">
                <svg
                  width={46}
                  height={48}
                  viewBox="0 0 46 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_643_1313)">
                    <path
                      d="M-27 106.682L102.418 -128.622L379 23.5L249.582 258.803L-27 106.682Z"
                      fill="#7ED956"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_643_1313">
                      <rect width={46} height={48} fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div className="linknav highlighted">{navData.textHighlight}</div>
            </Link>
          </div>
        </nav>
        <div className="buttonmenu w-nav-button">
          <div className="menuicon w-icon-nav-menu" />
        </div>
      </div>
    </div>
  );
}
// Navbar Link
function NavbarLink({ item, fontSize }) {
  const location = useLocation();
  return (
    <Link
      to={item.slug ? item.slug : "#"}
      className={`linknav w-nav-link ${
        location.pathname === item.slug && "w--current"
      }`}
      style={{ fontSize: fontSize }}
    >
      {item.text}
    </Link>
  );
}
// Navbar Dropdown
function NavbarDropdown({ item, fontSize }) {
  const [backgroundImage, setBackgroundImage] = useState(
    item?.subLinks[0]?.image?.data?.attributes?.url
  );

  return (
    <div data-hover="true" data-delay={0} className="dropdown w-dropdown">
      <div className="dropdownnav w-dropdown-toggle">
        {item.slug ? (
          <NavbarDropdownLink item={item} fontSize={fontSize} />
        ) : (
          <NavbarDropdownText item={item} fontSize={fontSize} />
        )}
        <div className="icondropdown w-icon-dropdown-toggle" />
      </div>

      {/* Dropdown */}
      <nav className="dropdownlist w-dropdown-list">
        <div className="divlinkdropdown">
          {item.subLinks &&
            item.subLinks.map((subItem) => {
              return (
                <NavbarDropdownSubLink
                  key={uuid()}
                  subItem={subItem}
                  fontSize={fontSize}
                  setBackgroundImage={setBackgroundImage}
                  backgroundImage={backgroundImage}
                />
              );
            })}
        </div>

        <img
          src={backgroundImage}
          loading="lazy"
          alt=""
          className="imagedropdown"
        />
      </nav>
    </div>
  );
}

// Navbar Dropdown where link is a URL.
function NavbarDropdownLink({ item, fontSize }) {
  const location = useLocation();
  return (
    <Link
      to={item.slug}
      className={`linknav dropdown ${
        item.slug && location.pathname === item.slug && "w--current"
      }`}
      style={{ fontSize: fontSize, textDecoration: "none"}}
    >
      {item.text}
    </Link>
  );
}

// Navbar Dropdown where Link is not a URL.
function NavbarDropdownText({ item, fontSize }) {
  return (
    <div
      className="linknav"
      style={{ fontSize: fontSize, textDecoration: "none" }}
    >
      {item.text}
    </div>
  );
}

// Handle background image.
function handleHover(url, setBackgroundImage) {
  setBackgroundImage(url);
}

// Dropdown Sub Link
function NavbarDropdownSubLink({ subItem, setBackgroundImage }) {
  const location = useLocation();

  return (
    <Link
      onMouseOver={() =>
        handleHover(
          subItem.image.data && subItem.image.data.attributes.url,
          setBackgroundImage
        )
      }
      to={subItem.slug ? subItem.slug : "#"}
      className={`linkdropdown w-dropdown-link ${
        location.pathname === subItem.slug && "w--current"
      }`}
    >
      {subItem.text}
    </Link>
  );
}
export default Navbar;

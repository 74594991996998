// Import Functions.
import React, { useEffect } from "react";
import WebFont from "webfontloader";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

function Head() {
  const { id } = useParams();
  const { slug } = useParams();

  useEffect(() => {
    // Fonts
    WebFont.load({
      google: {
        families: ["Jost:regular,500,600,700"],
      },
    });
  }, []);

  switch (window.location.pathname) {
    case "/":
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Moxie Personal Training</title>
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link href="css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="css/webflow.css" rel="stylesheet" type="text/css" />
          <link href="css/moxie-personal-training.webflow.css" rel="stylesheet" type="text/css" />
          <link href="images/favicon.png" rel="shortcut icon" type="image/x-icon" />
          <link href="images/webclip.png" rel="apple-touch-icon" />
        </Helmet>
      );

    case "/join":
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Join</title>
          <meta content="Gym Membership at Moxie Personal Training gives you access to incredible programs, services, and amenities that help you achieve your goals." name="description" />
          <meta content="Join" property="og:title" />
          <meta content="Gym Membership at Moxie Personal Training gives you access to incredible programs, services, and amenities that help you achieve your goals." property="og:description" />
          <meta content="Join" property="twitter:title" />
          <meta content="Gym Membership at Moxie Personal Training gives you access to incredible programs, services, and amenities that help you achieve your goals." property="twitter:description" />
          <meta property="og:type" content="website" />
          <meta content="summary_large_image" name="twitter:card" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link href="css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="css/webflow.css" rel="stylesheet" type="text/css" />
          <link href="css/moxie-personal-training.webflow.css" rel="stylesheet" type="text/css" />
          <link href="images/favicon.png" rel="shortcut icon" type="image/x-icon" />
          <link href="images/webclip.png" rel="apple-touch-icon" />
        </Helmet>
      );

    case "/about":
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>About</title>
          <meta content="MOXIE PERSONAL TRAINING, DAN CERTIFIED FITNESS TRAINER, NUTRITIONIST AND FOUNDER, WORLD-CLASS EQUIPMENT, CONTINUOUS CLEANING, FITNESS CLASSES" name="description" />
          <meta content="About" property="og:title" />
          <meta content="MOXIE PERSONAL TRAINING, DAN CERTIFIED FITNESS TRAINER, NUTRITIONIST AND FOUNDER, WORLD-CLASS EQUIPMENT, CONTINUOUS CLEANING, FITNESS CLASSES" property="og:description" />
          <meta content="About" property="twitter:title" />
          <meta
            content="MOXIE PERSONAL TRAINING, DAN CERTIFIED FITNESS TRAINER, NUTRITIONIST AND FOUNDER, WORLD-CLASS EQUIPMENT, CONTINUOUS CLEANING, FITNESS CLASSES"
            property="twitter:description"
          />
          <meta property="og:type" content="website" />
          <meta content="summary_large_image" name="twitter:card" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link href="css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="css/webflow.css" rel="stylesheet" type="text/css" />
          <link href="css/moxie-personal-training.webflow.css" rel="stylesheet" type="text/css" />
          {/* [if lt IE 9]><![endif] */}
          <link href="images/favicon.png" rel="shortcut icon" type="image/x-icon" />
          <link href="images/webclip.png" rel="apple-touch-icon" />
        </Helmet>
      );

    case "/contact":
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Contact</title>
          <meta content="Want to know more about Moxie Personal Training or need to get in touch? Check out our FAQ or send a question our way" name="description" />
          <meta content="Contact" property="og:title" />
          <meta content="Want to know more about Moxie Personal Training or need to get in touch? Check out our FAQ or send a question our way" property="og:description" />
          <meta content="Contact" property="twitter:title" />
          <meta content="Want to know more about Moxie Personal Training or need to get in touch? Check out our FAQ or send a question our way" property="twitter:description" />
          <meta property="og:type" content="website" />
          <meta content="summary_large_image" name="twitter:card" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link href="css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="css/webflow.css" rel="stylesheet" type="text/css" />
          <link href="css/moxie-personal-training.webflow.css" rel="stylesheet" type="text/css" />
          {/* [if lt IE 9]><![endif] */}
          <link href="images/favicon.png" rel="shortcut icon" type="image/x-icon" />
          <link href="images/webclip.png" rel="apple-touch-icon" />
        </Helmet>
      );

    case `/${encodeURIComponent(slug)}`:
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Services</title>
          <meta content="All Our Classes Are Simple To Follow, Science-Backed And Tailored To Your Goal Aim to help People live a fit and healthy life." name="description" />
          <meta content="Classes" property="og:title" />
          <meta content="All Our Classes Are Simple To Follow, Science-Backed And Tailored To Your Goal Aim to help People live a fit and healthy life." property="og:description" />
          <meta content="Classes" property="twitter:title" />
          <meta content="All Our Classes Are Simple To Follow, Science-Backed And Tailored To Your Goal Aim to help People live a fit and healthy life." property="twitter:description" />
          <meta property="og:type" content="website" />
          <meta content="summary_large_image" name="twitter:card" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link href="css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="css/webflow.css" rel="stylesheet" type="text/css" />
          <link href="css/moxie-personal-training.webflow.css" rel="stylesheet" type="text/css" />
          <link href="images/favicon.png" rel="shortcut icon" type="image/x-icon" />
          <link href="images/webclip.png" rel="apple-touch-icon" />
        </Helmet>
      );

    case `/${encodeURIComponent(slug)}/${id}`:
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Listing</title>
          <meta content="At Moxie Personal Training We help busy folks improve their life through intelligent and fun coaching." name="description" />
          <meta content="Listing" property="og:title" />
          <meta content="At Moxie Personal Training We help busy folks improve their life through intelligent and fun coaching." property="og:description" />
          <meta content="Listing" property="twitter:title" />
          <meta content="At Moxie Personal Training We help busy folks improve their life through intelligent and fun coaching." property="twitter:description" />
          <meta property="og:type" content="website" />
          <meta content="summary_large_image" name="twitter:card" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link href="../css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="../css/webflow.css" rel="stylesheet" type="text/css" />
          <link href="../css/moxie-personal-training.webflow.css" rel="stylesheet" type="text/css" />
          <link href="images/favicon.png" rel="shortcut icon" type="image/x-icon" />
          <link href="images/webclip.png" rel="apple-touch-icon" />
        </Helmet>
      );

    case "/shop":
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Shop</title>
          <meta content="Experience affordable luxury - Healthy and Tasty Power Bowls, Moxie Merchandise and more. Don't miss" name="description" />
          <meta content="Shop" property="og:title" />
          <meta content="Experience affordable luxury - Healthy and Tasty Power Bowls, Moxie Merchandise and more. Don't miss" property="og:description" />
          <meta content="Shop" property="twitter:title" />
          <meta content="Experience affordable luxury - Healthy and Tasty Power Bowls, Moxie Merchandise and more. Don't miss" property="twitter:description" />
          <meta property="og:type" content="website" />
          <meta content="summary_large_image" name="twitter:card" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link href="css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="css/webflow.css" rel="stylesheet" type="text/css" />
          <link href="css/moxie-personal-training.webflow.css" rel="stylesheet" type="text/css" />
          <link href="images/favicon.png" rel="shortcut icon" type="image/x-icon" />
          <link href="images/webclip.png" rel="apple-touch-icon" />
        </Helmet>
      );

    case "/shop/success":
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Shop</title>
          <meta content="Experience affordable luxury - Healthy and Tasty Power Bowls, Moxie Merchandise and more. Don't miss" name="description" />
          <meta content="Shop" property="og:title" />
          <meta content="Experience affordable luxury - Healthy and Tasty Power Bowls, Moxie Merchandise and more. Don't miss" property="og:description" />
          <meta content="Shop" property="twitter:title" />
          <meta content="Experience affordable luxury - Healthy and Tasty Power Bowls, Moxie Merchandise and more. Don't miss" property="twitter:description" />
          <meta property="og:type" content="website" />
          <meta content="summary_large_image" name="twitter:card" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link href="../css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="../css/webflow.css" rel="stylesheet" type="text/css" />
          <link href="../css/moxie-personal-training.webflow.css" rel="stylesheet" type="text/css" />
          <link href="images/favicon.png" rel="shortcut icon" type="image/x-icon" />
          <link href="images/webclip.png" rel="apple-touch-icon" />
        </Helmet>
      );

    case "/template":
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Template</title>
          <meta content="Template" property="og:title" />
          <meta content="Template" property="twitter:title" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link href="css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="css/webflow.css" rel="stylesheet" type="text/css" />
          <link href="css/moxie-personal-training.webflow.css" rel="stylesheet" type="text/css" />
          <link href="images/favicon.png" rel="shortcut icon" type="image/x-icon" />
          <link href="images/webclip.png" rel="apple-touch-icon" />
        </Helmet>
      );

    case "/testimonials":
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Testimonials</title>
          <meta
            content="Allyson Foster. IIn earlier years, I had a very active lifestyle spending most of my childhood into my 20s playing hockey. I never worried about my weight and it was easy to stay in shape. After 2 wonderful children and barely any lifestyle changes, I had a difficult time getting back into shape and losing the weight I had put on. I did not have ANY motivation to go to a gym!‍In earlier years, I had a very active lifestyle spending most of my childhood into my 20s playing hockey. I never worried about my weight and it was easy to stay in shape. After 2 wonderful children and barely any lifestyle changes, I had a difficult time getting back into shape and losing the weight I had put on. I did not have ANY motivation to go to a gym!‍In earlier years, I had a very active lifestyle spending most of my childhood into my 20s playing hockey. I never worried about my weight and it was easy to stay in shape. After 2 wonderful children and barely any lifestyle changes, I had a difficult time getting back into shape and losing the weight I had put on. I did not have ANY motivation to go to a gym!"
            name="description"
          />
          <meta content="Testimonials" property="og:title" />
          <meta
            content="Allyson Foster. IIn earlier years, I had a very active lifestyle spending most of my childhood into my 20s playing hockey. I never worried about my weight and it was easy to stay in shape. After 2 wonderful children and barely any lifestyle changes, I had a difficult time getting back into shape and losing the weight I had put on. I did not have ANY motivation to go to a gym!‍In earlier years, I had a very active lifestyle spending most of my childhood into my 20s playing hockey. I never worried about my weight and it was easy to stay in shape. After 2 wonderful children and barely any lifestyle changes, I had a difficult time getting back into shape and losing the weight I had put on. I did not have ANY motivation to go to a gym!‍In earlier years, I had a very active lifestyle spending most of my childhood into my 20s playing hockey. I never worried about my weight and it was easy to stay in shape. After 2 wonderful children and barely any lifestyle changes, I had a difficult time getting back into shape and losing the weight I had put on. I did not have ANY motivation to go to a gym!"
            property="og:description"
          />
          <meta content="Testimonials" property="twitter:title" />
          <meta
            content="Allyson Foster. IIn earlier years, I had a very active lifestyle spending most of my childhood into my 20s playing hockey. I never worried about my weight and it was easy to stay in shape. After 2 wonderful children and barely any lifestyle changes, I had a difficult time getting back into shape and losing the weight I had put on. I did not have ANY motivation to go to a gym!‍In earlier years, I had a very active lifestyle spending most of my childhood into my 20s playing hockey. I never worried about my weight and it was easy to stay in shape. After 2 wonderful children and barely any lifestyle changes, I had a difficult time getting back into shape and losing the weight I had put on. I did not have ANY motivation to go to a gym!‍In earlier years, I had a very active lifestyle spending most of my childhood into my 20s playing hockey. I never worried about my weight and it was easy to stay in shape. After 2 wonderful children and barely any lifestyle changes, I had a difficult time getting back into shape and losing the weight I had put on. I did not have ANY motivation to go to a gym!"
            property="twitter:description"
          />
          <meta property="og:type" content="website" />
          <meta content="summary_large_image" name="twitter:card" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link href="css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="css/webflow.css" rel="stylesheet" type="text/css" />
          <link href="css/moxie-personal-training.webflow.css" rel="stylesheet" type="text/css" />
          <link href="images/favicon.png" rel="shortcut icon" type="image/x-icon" />
          <link href="images/webclip.png" rel="apple-touch-icon" />
        </Helmet>
      );

    case "/promotion":
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Moxie Personal Traning</title>
          <meta content="Moxie Personal Training" name="description" />
          <meta content="Challenge" property="og:title" />
          <meta content="Moxie Personal Training" property="og:description" />
          <meta content="Challenge" property="twitter:title" />
          <meta content="Moxie Personal Training" property="twitter:description" />
          <meta property="og:type" content="website" />
          <meta content="summary_large_image" name="twitter:card" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link href="css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="css/webflow.css" rel="stylesheet" type="text/css" />
          <link href="css/challenge.css" rel="stylesheet" type="text/css" />
          <link href="images/favicon.png" rel="shortcut icon" type="image/x-icon" />
          <link href="images/webclip.png" rel="apple-touch-icon" />
        </Helmet>
      );
    case "/videos":
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Videos</title>
          <meta content="Want to know more about Moxie Personal Training or need to get in touch? Check out our FAQ or send a question our way" name="description" />
          <meta content="Contact" property="og:title" />
          <meta content="Want to know more about Moxie Personal Training or need to get in touch? Check out our FAQ or send a question our way" property="og:description" />
          <meta content="Contact" property="twitter:title" />
          <meta content="Want to know more about Moxie Personal Training or need to get in touch? Check out our FAQ or send a question our way" property="twitter:description" />
          <meta property="og:type" content="website" />
          <meta content="summary_large_image" name="twitter:card" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link href="css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="css/webflow.css" rel="stylesheet" type="text/css" />
          <link href="css/moxie-personal-training.webflow.css" rel="stylesheet" type="text/css" />
          {/* [if lt IE 9]><![endif] */}
          <link href="images/favicon.png" rel="shortcut icon" type="image/x-icon" />
          <link href="images/webclip.png" rel="apple-touch-icon" />
        </Helmet>
      );

    default:
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Not Found</title>
          <meta content="Not Found" property="og:title" />
          <meta content="Not Found" property="twitter:title" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link href="css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="css/webflow.css" rel="stylesheet" type="text/css" />
          <link href="css/moxie-personal-training.webflow.css" rel="stylesheet" type="text/css" />
          <link href="images/favicon.png" rel="shortcut icon" type="image/x-icon" />
          <link href="images/webclip.png" rel="apple-touch-icon" />
        </Helmet>
      );
  }
}

export default Head;

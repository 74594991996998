// This file defines the strapi endpoints you want to pull dtata from (collections & single types)
// This data will be fetched every time the page is loaded or refreshed

// slug = database table name, also known as API ID (Plural)
// fields = what columns from the table do you want to use?
// populate = how to show nested components & relations (doc: https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/populating-fields.html#relation-media-fields)
// There's two formats for doing this. Populate can be a string or qs.stringify()

import qs from "qs";

export const strapiPublicEndpoints = [
  // Single Types
  {
    slug: "about",
    fields: [
      "title",
      "titleDeck",
      "imageA",
      "headingA",
      "headingDeckA",
      "richTextA",
      "imageFixedBackground",
      "headingFixedBackground",
      "richTextFixedBackground",
      "headingB",
      "imageB",
      "headingMap",
      "mapURL",
    ],
    populate: "populate=*",
  },
  {
    slug: "contact",
    fields: ["imageA", "imageB", "imageC"],
    populate: "populate=*",
  },
  {
    slug: "footer",
    fields: ["logo", "socialMediaLabel", "socialMediaIcons", "civiconnectLogo", "attributions"],
    populate: qs.stringify({
      populate: {
        socialMediaIcons: {
          populate: "*",
        },
        logo: {
          populate: "*",
        },
        civiconnectLogo: {
          populate: "*",
        },
        attributions: {
          populate: "*",
        },
      },
    }),
  },
  {
    slug: "join",
    fields: [
      "headerPhoto",
      "title",
      "headingA",
      "arrowBulletList",
      "button",
      "imageFixedBackground",
      "headingB",
      "headingFAQ",
      "sectionButton",
      "featuredTestimonialName",
      "featuredTestimonialRichText",
      "featuredTestimonialImage",
    ],
    populate: qs.stringify({
      populate: {
        headerPhoto: {
          populate: "*",
        },
        arrowBulletList: {
          populate: "*",
        },
        button: {
          populate: "*",
        },
        imageFixedBackground: {
          populate: "*",
        },
        sectionButton: {
          populate: "*",
        },
        featuredTestimonialImage: {
          populate: "*",
        },
      },
    }),
  },
  {
    slug: "landing",
    fields: [
      "heroImage",
      "title",
      "titleDeck",
      "textHero",
      "buttonHero",
      "headingLanding",
      "headingA",
      "arrowBulletList",
      "richTextA",
      "imageA",
      "buttonA",
      "sectionButtonA",
      "headingB",
      "richTextB",
      "buttonB",
      "sectionButtonB",
      "imageB",
    ],
    populate: qs.stringify({
      populate: {
        heroImage: {
          populate: "*",
        },
        buttonHero: {
          populate: "*",
        },
        arrowBulletList: {
          populate: "*",
        },
        imageA: {
          populate: "*",
        },
        buttonA: {
          populate: "*",
        },
        sectionButtonA: {
          populate: "*",
        },
        buttonB: {
          populate: "*",
        },
        sectionButtonB: {
          populate: "*",
        },
        imageB: {
          populate: "*",
        },
      },
    }),
  },
  {
    slug: "navbar",
    fields: ["logo", "links", "textHighlight", "slugHighlight"],
    populate: qs.stringify({
      populate: {
        logo: {
          populate: "populate=*",
        },
        links: {
          populate: {
            subLinks: {
              populate: "*",
            },
          },
        },
      },
    }),
  },
  {
    slug: "shop",
    fields: ["title", "titleDeck", "disclaimer"],
    populate: "populate=*",
  },
  {
    slug: "store-button",
    fields: ["appleStoreURL", "googlePlayStoreURL", "appleStoreButton", "googlePlayStoreButton"],
    populate: "populate=*",
  },
  {
    slug: "testimonial-page",
    fields: ["title", "titleDeck", "heading", "headingDeck"],
    // populate: "populate=*",
    populate: qs.stringify({
      populate: {
        testimonial_sliders: {
          populate: "*",
        },
        testimonials: {
          populate: "*",
        },
      },
    }),
  },
  {
    slug: "video-page",
    fields: ["title", "titleDeck", "videos"],
    // populate: "populate=*",
    populate: qs.stringify({
      populate: {
        videos: {
          populate: {
            video: {
              populate: "*",
            },
            coverPhoto: {
              populate: "*",
            },
          },
        },
      },
    }),
  },
  {
    slug: "promotion",
    fields: [],
    populate: "populate=*",
    // populate: qs.stringify({
    //   populate: {
    //     testimonial_sliders: {
    //       populate: "*",
    //     },
    //     testimonials: {
    //       populate: "*",
    //     },
    //   },
    // }),
  },

  // Collections
  {
    slug: "faqs",
    fields: ["header", "body"],
    populate: "populate=*",
  },
  {
    slug: "item-categories",
    fields: ["title", "titleDeck", "name"],
    populate: "populate=*",
  },
  {
    slug: "listings",
    fields: ["name", "price", "sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "richText", "buttonListing", "service_type", "image"],
    populate: qs.stringify({
      populate: {
        buttonListing: {
          populate: "*",
        },
        image: {
          populate: "*",
        },
        service_type: {
          populate: "populate=*",
        },
      },
    }),
  },
  {
    slug: "services",
    fields: ["name", "nameDeck", "image", "slug"],
    populate: "populate=*",
  },

  {
    slug: "shop-items",
    fields: ["name", "price", "photo"],
    populate: "populate=*",
  },

  {
    slug: "testimonials",
    fields: ["image", "name", "richText"],
    populate: "populate=*",
  },
];

export const strapiAuthenticatedEndpoints = [];

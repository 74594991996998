// Import
import React from "react";
import Page from "../components/Page";
import { useSelector } from "react-redux";

// Import for Display
import Preloader from "../components/Preloader";
import TestimonialSlider from "../components/TestimonialSlider";
import RichText from "../components/RichText";
import LimitText from "../components/LimitText";

function TestimonialsPage() {
  const singleTypeSlug = "testimonial-page";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // Grab testimonials.
  let sliderData = cmsData?.testimonial_sliders?.data;
  let otherData = cmsData?.testimonials?.data;

  // Only load page if data has been retrieved.
  if (!cmsData) return <Preloader />;

  return (
    <Page data-wf-page="638f994ab0904e3d1e2252b3" data-wf-site="6384e1d3e0ae24517f172c0b">
      <div className="content">
        <div className="section wf-section" >
          <div className="container w-container" >

            {/* Title */}
            <div>
              <h1  className="textcenter">{cmsData.title}</h1>
              <div className="label textcenter">{cmsData.titleDeck}</div>
            </div>
          </div>
        </div>

        {/* Slider */}
        <TestimonialSlider data={sliderData} />

        {/* Other Testimonials */}
        <div className="section wf-section">
          <div className="container w-container">
            <div>
              <h2  className="textcenter">{cmsData.heading}</h2>
              <div className="label textcenter"> {cmsData.headingDeck} </div>
            </div>
          </div>
        </div>


        <div className="section wf-section">
          <div className="container w-container">
            <div className="divhflex wrap">
              {otherData.map((el) => {
                return (
                  // Image
                  <div className="divtestimonialpanel rounding dropshadow">
                    <div className="divcircle">
                    <img
                      src={el?.attributes?.image?.data?.attributes?.url || "https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg"}
                      loading="lazy"
                      alt=""
                      className="circle"
                    />
                    </div>


                    <div className="divvflex testimonial">
                      <div className="embedquote w-embed">
                        <svg width="auto" height="auto" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clipPath="url(#clip0_588_1336)">
                            <path d="M20.2817 29L22.9859 19H20.507L16 29H20.2817ZM29.2958 29L32 19H29.5211L25.0141 29H29.2958Z" fill="#7ED956" />
                            <path
                              d="M45.5 24C45.5 35.8741 35.8741 45.5 24 45.5C12.1259 45.5 2.5 35.8741 2.5 24C2.5 12.1259 12.1259 2.5 24 2.5C35.8741 2.5 45.5 12.1259 45.5 24Z"
                              stroke="#7ED956"
                              strokeWidth={5}
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_588_1336">
                              <rect  width={48} height={48} fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <RichText text={LimitText(el.attributes.richText, 400)}/>
                      <p className="textright allcaps" style={{marginTop:"auto"}}>- {el.attributes.name} </p>
                    </div>
                  </div>
                );
              })}

            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default TestimonialsPage;

// Import Functions.
import React from "react";
import { useShopFunctions } from "../hooks/useShopFunctions";

const ItemCard = ({ data }) => {
  const {
    quantity,
    decreaseQuantity,
    increaseQuantity,
    changeQuantity,
    handlePurchase,
  } = useShopFunctions();

  return (
    <div className="divitemlisting dropshadow rounding">
      <div className="div-block">
        <img
          src={data?.attributes?.photo?.data?.attributes?.url}
          loading="lazy"
          alt=""
          className="divshopitemimage"
        />
      </div>
      {/* Name */}
      <div className="textshopitem textcenter">{data.attributes.title}</div>

      {/* Price */}
      <div>{formatter.format(data.attributes.price)}</div>

      {/* Quantity */}
      <div style ={{whiteSpace:"nowrap"}}>
        <button
          onClick={decreaseQuantity}
          min="0"
          style={{ backgroundColor: "black" , height: "24px", width: "24px"}}
        >
          <p style={{ color: "white"}}>&#9472;</p>
        </button>
        <input
          type="number"
          onChange={changeQuantity}
          value={quantity}
          min="0"
          style={{ border: "none"}}
        />

        <button style={{ backgroundColor: "black", height: "24px", width: "24px" }} onClick={increaseQuantity}>
          <p style={{ color: "white" }}>&#43;</p>
        </button>
      </div>

      {/* Purchase*/}
      <button
        className="buttonlanding w-inline-block"
        onClick={() => {
          handlePurchase({
            name: data.attributes.title,
            price: data.attributes.price,
          });
        }}
      >
        Purchase
      </button>
    </div>
  );
};

export default ItemCard;

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

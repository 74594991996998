// DOCUMENTATION:
// https://reactrouter.com/docs/en/v6

// This file sets up the page navigation or the sitemap of our website.
// This is not our Navbar component

import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";

// Import the necessary page files from ../pages/Something.js
import IndexPage from "../pages/IndexPage";
import ErrorPage from "../pages/ErrorPage";
import ContactPage from "../pages/ContactPage";
import ShopPage from "../pages/ShopPage";
import AboutPage from "../pages/AboutPage";
import ServicesPage from "../pages/ServicesPage";
import JoinPage from "../pages/JoinPage";
import ListingPage from "../pages/ListingPage";
import TestimonialsPage from "../pages/TestimonialsPage";
import ChallengePage from "../pages/ChallengePage";
import VideoPage from "../pages/VideoPage";

function Navigation(props) {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          {/* We need to add a route for each of our pages. 
          Path is the page slug www.something.com/slug. 
          Element is the React component to display when we get to that slug. */}
          <Route path="/" element={<IndexPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/shop" element={<ShopPage />} />
          <Route path="/join" element={<JoinPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/:slug/:id" element={<ListingPage />} />
          <Route path="/:slug" element={<ServicesPage />} />
          <Route path="/testimonials" element={<TestimonialsPage />} />
          <Route path="/promotion" element={<ChallengePage />} />
          <Route path="/videos" element={<VideoPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default Navigation;

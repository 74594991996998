// Import Functions.
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";

function Footer() {
  // Access data.
  const { data } = useSelector((state) => state.cms);
  const singleTypeSlug = "footer";
  const footerData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // Only display if data has been loaded.
  if (!footerData) return null;

  return (
    <div className="section black wf-section">
      <div className="container containervflex aligncenter w-container">
        <div className="w-layout-grid gridfooter">
          {/* Logo */}
          <Link
            to="/"
            id="w-node-a0ef9f4a-fe56-21b7-cc9c-0b416eab51d1-83e20f8c"
            aria-current="page"
            className="w-inline-block w--current"
          >
            <img
              src={footerData.logo.data && footerData.logo.data.attributes.url}
              loading="lazy"
              alt=""
              className="logo"
            />
          </Link>

          {/* Middle Section */}
          <div>
            <div className="label textwhite textcenter">
              {footerData.richText}
            </div>
          </div>

          {/* Social Media Section */}
          <div className="divvflex">
            <div className="label greentext textcenter">
              {footerData.socialMediaLabel}
            </div>
            <div className="divhflex">
              {footerData.socialMediaIcons &&
                footerData.socialMediaIcons.map((item) => {
                  return (
                    <a href={item.url} className="w-inline-block" key={uuid()}>
                      <img
                        src={item.icon.data && item.icon.data.attributes.url}
                        loading="lazy"
                        alt={item.alt}
                        className="socialmediaicon"
                      />
                    </a>
                  );
                })}
            </div>
          </div>
        </div>

        {/* Civiconnect Logo */}
        <div className="divvflex aligncenter smallgap">
          <a href = "https://www.civiconnect.ca/">
            <img
              src={
                footerData.civiconnectLogo.data &&
                footerData.civiconnectLogo.data.attributes.url
              }
              loading="lazy"
              alt=""
              className="image"
            />
          </a>

          {/* Attributions */}
          <div className="credit">
            {footerData.attributions &&
              footerData.attributions.map((item) => {
                return (
                  <a
                    href={item.url}
                    target="_blank"
                    className="linkcredit"
                    rel="noreferrer"
                    key={uuid()}
                  >
                    {item.text}
                  </a>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Footer;

// Uses the stripe API to create a stripe hosted checkout session
// calls backend/createCheckoutSession. This creates a session with stripe using the stripe secret key, and returns it to our frontend.
// Our frontend renders the checkout session that is hosted on stripe.
// on cancel, were redirected back to the shop
// on success, were redirected to the ShopSuccessPage.js

// on success, backend/checkoutSessionCompleted is also called, this reduces the quantityAvailable field in the database.
// For this to actually happen, we have to configure stripe in one of two ways:
// For live mode:
//  - add a webhook pointing to backendurl/api/shop-items/checkoutSessionCompleted this webhook is triggered on checkout.session.completed
// For dev mode:
// - install stripe cli in terminal: brew install stripe/stripe-cli/stripe
// - login to stripe: stripe login
// - link the CLI to our local backend: stripe listen --forward-to localhost:1337/api/shop-items/checkoutSessionComplete

// Import Functions
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Page from "../components/Page";
import { v4 as uuid } from "uuid";
import Preloader from "../components/Preloader";
import ItemCard from "../components/ItemCard";

function ShopPage() {
  const { data } = useSelector((state) => state.cms);

  // Access data.
  const singleTypeSlug = "shop";
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  const collectionSlug = "shop-items";
  const collectionData = data[collectionSlug];

  const categorySlug = "item-categories";
  const categoryData = data[categorySlug];

  // Only display if data has been loaded.
  if (!collectionData) return <Preloader />;
  return (
    <Page>
      <div className="content">
        <div className="section wf-section">
          <div className="container w-container">
            <div className="divvflex">
              <h1 className="textcenter">{cmsData.title}</h1>
              <div className="label textcenter">{cmsData.titleDeck}</div>
              <div className="textcenter">{cmsData.disclaimer}</div>
            </div>
          </div>
        </div>

        {/* start */}

        {categoryData &&
          categoryData.map((el) => {
            return (
              <div key={uuid()} className="section wf-section">
                <div className="container containervflex aligncenter w-container">
                  <div>
                    <h2 className="textcenter">{el.attributes.title}</h2>
                    <div className="label textcenter">{el.attributes.titleDeck}</div>
                  </div>
                  <div className="w-layout-grid gridshop">
                    {/* repeat this card */}
                    {collectionData &&
                      // eslint-disable-next-line array-callback-return
                      collectionData.map((item) => {
                        if (el.id === item?.attributes?.item_category?.data?.id) return <ItemCard key={uuid()} data={item} />;
                      })}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </Page>
  );
}

export default ShopPage;

import { useState } from "react";
import { useDispatch } from "react-redux";
import * as ShopActions from "../store/actions/shop";

// const stripeSecretKey = process.env.STRIPE_SECRET_KEY;
// const stripePublicKey = process.env.STRIPE_PUBLIC_KEY;
// const stripe = require("stripe")(stripeSecretKey);

// handles Strapi auth flow: login
export const useShopFunctions = () => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const [quantityInStock, setQuantityInStock] = useState();

  //   check for a maximum attribute
  const increaseQuantity = (e) => {
    let max = parseInt(e.target.getAttribute("max"));
    let newQty = quantity + 1;
    if (max && newQty > max) newQty = max;
    changeQuantity(newQty);
  };

  const decreaseQuantity = (e) => {
    let min = parseInt(e.target.getAttribute("min"));
    let newQty = quantity - 1;
    if (newQty <= min) newQty = min;
    changeQuantity(newQty);
  };

  const changeQuantity = (val) => {
    let num = val;
    if (num.target) {
      num = parseFloat(num.target.value);
    }
    setQuantity(num);
  };
  // create a function to be called by our form
  const handlePurchase = (data) => {
    dispatch(ShopActions.purchase(data, quantity));
  };

  // return those functions
  return {
    handlePurchase,
    quantity,
    changeQuantity,
    increaseQuantity,
    decreaseQuantity,
    setQuantityInStock,
  };
};

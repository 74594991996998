// Import Functions.
import React from "react";

function DayListing(item, text, color) {
  if (item === true) {
    if (color && color === "G") {
      return <div className="greentext">{text}</div>;
    }
    return <div className="textwhite">{text}</div>;
  }
  return <div>{text}</div>;
}

export default DayListing;

// Import Functions.
import React, { useEffect } from "react";
import * as ReactDOM from "react-dom";

// Import functions for display.
import Banner from "./Banner";
import Footer from "./Footer";
import Head from "./Head";
import Navbar from "./Navbar";

function Page(props) {
  // change <html> tag attributes
  useEffect(() => {
    // if were in dev mode and wf jhtml attributes arent set, let dev know we have a problem
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      if (!props["data-wf-page"] || !props["data-wf-site"])
        console.warn("Did you forget to set your HTML attibutes on your <Page> component? This will cause problems with Webflow animations. - Liam");
    }

    // fix webflow animation bug
    var html = document.getElementsByTagName("html")[0];
    var node = ReactDOM.findDOMNode(html);
    node.setAttribute("data-wf-page", props["data-wf-page"]);
    node.setAttribute("data-wf-site", props["data-wf-site"]);
    window.Webflow && window.Webflow.destroy();
    window.Webflow && window.Webflow.ready();
    window.Webflow && window.Webflow.require("ix2").init();
    document.dispatchEvent(new Event("readystatechange"));
  }, [props]);

  return (
    <>
      <Banner />
      <Head />
      {!props.hideNav && <Navbar />}
      <div className={!props.hideNav && "pagewrap"}>
        {props.children}
        <div>{!props.hideNav && <Footer />}</div>
      </div>
    </>
  );
}

export default Page;

// Import Functions.
import React, { useState } from "react";
import RichText from "./RichText";
import LimitText from "./LimitText"

const TestimonialSlider = ({ data }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleSlideChange = (val) => {
    let tmp = currentSlide + val;
    if (tmp < 0) {
      tmp = data.length - 1;
    } else if (tmp === data.length) {
      tmp = 0;
    }

    setCurrentSlide(tmp);
  };

  if (!data) return null;

  return (
    <section className="section sectionslider wf-section">
      <div className="container containertestimonial w-container">
        <div className="c-slidertestimonials c-slider">
          <div className="c-slider-mask">
            <Slide item={data[currentSlide]} />
          </div>
          <div
            className=" w-slider-arrow-left"
            onClick={() => handleSlideChange(-1)}
          >
            <div className="w-icon-slider-left" />
          </div>
          <div
            className=" w-slider-arrow-right"
            onClick={() => handleSlideChange(1)}
          >
            <div className="w-icon-slider-right" />
          </div>
          {/* <div className="testimonialslidernav w-slider-nav w-shadow w-round" /> */}
        </div>
      </div>
    </section>
  );
};

export default TestimonialSlider;

const Slide = ({ item }) => {
  return (
    <div className="w-slide">
      <div className="divvflex aligncenter">
        <div className="slidetestimonial">
          <div
            id="w-node-a7a215ee-c153-1589-0312-b89f03f75cde-1e2252b3"
            className="divvflex divtestimonial"
          >
            {/* Embed */}
            <div className="embedquote w-embed">
              <svg
                width="auto"
                height="auto"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_588_1336)">
                  <path
                    d="M20.2817 29L22.9859 19H20.507L16 29H20.2817ZM29.2958 29L32 19H29.5211L25.0141 29H29.2958Z"
                    fill="#7ED956"
                  />
                  <path
                    d="M45.5 24C45.5 35.8741 35.8741 45.5 24 45.5C12.1259 45.5 2.5 35.8741 2.5 24C2.5 12.1259 12.1259 2.5 24 2.5C35.8741 2.5 45.5 12.1259 45.5 24Z"
                    stroke="#7ED956"
                    strokeWidth={5}
                  />
                </g>
                <defs>
                  <clipPath id="clip0_588_1336">
                    <rect width={48} height={48} fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>

            {/* Name and Deck */}
            <h3 className="textwhite">{item?.attributes?.name} </h3>
            <div className="label textwhite">{item?.attributes?.subtitle}</div>

            {/* Text */}
            <RichText
              style={{ maxWidth: 100, width: 100, flex: 1 }}
              // Ensures that it will always be 1000 characters.
              text={LimitText(item?.attributes?.richText, 1000)}
              className="textwhite"
            />
          </div>
          <img
            style={{ width: 600, height: 300, flex: 1 }}
            src={item?.attributes?.image?.data?.attributes?.url}
            loading="lazy"
            alt=""
            className="imageslider rounding"
          />
        </div>
      </div>
    </div>
  );
};

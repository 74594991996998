// Import functions.
import React from "react";
import Page from "../components/Page";
import { useSelector } from "react-redux";
import RichText from "../components/RichText";
import Preloader from "../components/Preloader";

function AboutPage() {
  // access our data so that we can display it
  const singleTypeSlug = "about";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  // Only display if data has been loaded.

  if (!cmsData) return <Preloader />;

  return (
    <Page
      data-wf-page="6384e28babfbf45a6129ed39"
      data-wf-site="6384e1d3e0ae24517f172c0b"
    >
      <div className="content">
        <div className="section wf-section">
          <div className="containervflex aligncenter w-container">
            <h1 className="textcenter">{cmsData.title}</h1>
            <div className="label textcenter">{cmsData.titleDeck}</div>
          </div>
        </div>
        <div className="section referencerelative wf-section">
          <div className="divaboutcustom" />
          <div className="container w-container">
            <div className="gridcustomsection">
              <img
                src={cmsData.imageA.data && cmsData.imageA.data.attributes.url}
                loading="lazy"
                alt=""
                className="aboutimage"
              />
              <div
                id="w-node-_1ade500f-f93f-8b44-caec-23a3f651855b-6129ed39"
                className="divcustomabout textwhitefaded"
              >
                {/* Section A */}
                <h2 className="greentext textcentermobile">
                  {cmsData.headingA}
                </h2>
                <h4 className="textwhite textcentermobile">
                  {cmsData.headingDeckA}
                </h4>
                <RichText className="textwhitefaded" text={cmsData.richTextA} />
              </div>
            </div>
          </div>
        </div>

        {/* Section Fixed */}
        <div
          className="section sectionfixed wf-section"
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${
              cmsData.imageFixedBackground.data &&
              cmsData.imageFixedBackground.data.attributes.url
            })`,
          }}
        >
          <div className="container w-container">
            <div className="textwhitefaded">
              <h2 className="textwhite textcenter">
                {cmsData.headingFixedBackground}
              </h2>
              <RichText
                className="textwhitefaded textcenter"
                text={cmsData.richTextFixedBackground}
              />
            </div>
          </div>
        </div>

        {/* Section B */}
        <div className="section wf-section">
          <div className="container w-container">
            <div className="w-layout-grid gridstory ">
              <div id="w-node-_9d56b804-185b-82eb-c0ec-aa5a76581346-6129ed39">
                <h2 className="textcentermobile ">{cmsData.headingB}</h2>
                <RichText text={cmsData.richTextB} />
              </div>
              <img
                src={cmsData.imageB.data && cmsData.imageB.data.attributes.url}
                loading="lazy"
                alt=""
                className="rounding"
              />
            </div>
          </div>
        </div>

        {/* Map */}
        <div className="section wf-section">
          <div className="container centered w-container">
            <h2 className="textcenter">{cmsData.headingMap}</h2>
            <div className="embed rounding w-embed w-iframe">
              <iframe
                title="Map"
                src={cmsData.mapURL}
                width="100%"
                height="500px"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default AboutPage;

// Import Functions.
import React from "react";
import { useSelector } from "react-redux";

function GooglePlayButton({ small }) {
  // Accessing Data
  const { data } = useSelector((state) => state.cms);
  const singleTypeSlug = "store-button";
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  if (!cmsData) return null;
  return (
    <a href={cmsData.googlePlayStoreURL} className="appstorebutton" target="_blank">
      <img
        src={cmsData.googlePlayStoreButton.data && cmsData.googlePlayStoreButton.data.attributes.url}
        loading="lazy"
        alt="The Google Store Icon"
        className="appstorebuttonimage"
        style={small && { width: 150 }}
      />
    </a>
  );
}
export default GooglePlayButton;

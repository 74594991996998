// Import functions for use.
import React from "react";
import Page from "../components/Page";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Panel from "../components/Panel";
import { v4 as uuid } from "uuid";

// Import for Display
import Preloader from "../components/Preloader";
import ErrorPage from "./ErrorPage";

function ServicesPage() {
  const { data } = useSelector((state) => state.cms);
  let { slug } = useParams();

  // Grab name of Service.
  const collectionSlug = "services";
  const collectionData = data[collectionSlug];

  // Only load if data has returned.
  if (!collectionData) return <Preloader />;

  // Pull page for the specific service.
  let el = collectionData.filter((el) => el.attributes.slug === "/" + slug || el.attributes.slug === slug)[0];

  // Return error page if no listing shows up.
  if (!el) return <ErrorPage />;

  // Grab all listings.
  const listingSlug = "listings";
  const listingData = data[listingSlug];

  // console.log(listingData);
  // Filter out the listings that have their service type match or ID.
  let filteredData = listingData?.filter((item) => item.attributes.service_type === el.attributes.name || item?.attributes?.service_type?.data?.id === el.id);

  // Change title of the page.
  document.title = el.attributes.name;
  return (
    <Page data-wf-page="638df743ac045201f71cb6b6" data-wf-site="6384e1d3e0ae24517f172c0b">
      <div className="content">
        <div className="section wf-section">
          <div className="containervflex alignstretch w-container">
            <h1 className="textcenter"> {el.attributes.name}</h1>
            <div className="label textcenter"> {el.attributes.nameDeck} </div>

            {/* Panels */}
            {filteredData &&
              // eslint-disable-next-line array-callback-return
              filteredData.map((item) => {
                return <Panel key={uuid()} item={item} />;
              })}
          </div>
        </div>
      </div>
    </Page>
  );
}

export default ServicesPage;

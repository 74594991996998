// Import Functions.
import React from "react";
import { Link } from "react-router-dom";
import DayListing from "../components/DayListing";

const Panel = ({ item }) => {
  return (
    <div className="divvflex">
      <Link
        to={`${item.id}`}
        className="panelstyling w-inline-block"
        style={{
          backgroundImage: `linear-gradient(45deg, rgba(0, 0, 0, 0.5), rgba(126, 217, 86, 0.5)), linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${
            item.attributes.image.data &&
            item.attributes.image.data.attributes.url
          })`,
        }}
      >
        <div className="w-layout-grid gridpanellisting">
          
          {/* Service Type */}
          <div
            id="w-node-_51c61675-59af-b3fe-934a-ed2d5177cfe0-5177cfde"
            className="textwhite textallcaps"
          >
            {item.attributes.service_type.data.attributes.name}
          </div>

          {/* Service Name */}
          <div
            id="w-node-_51c61675-59af-b3fe-934a-ed2d5177cfe2-5177cfde"
            className="divavailabilitytext"
          >
            {/* Availability */}
            {DayListing(item.attributes.sunday, "S")}
            {DayListing(item.attributes.monday, "M")}
            {DayListing(item.attributes.tuesday, "T")}
            {DayListing(item.attributes.wednesday, "W")}
            {DayListing(item.attributes.thursday, "T")}
            {DayListing(item.attributes.friday, "F")}
            {DayListing(item.attributes.saturday, "S")}
          </div>
          <div
            id="w-node-_51c61675-59af-b3fe-934a-ed2d5177cff1-5177cfde"
            className="label greentext"
          >
            {item.attributes.name}
          </div>
          <div
            id="w-node-_51c61675-59af-b3fe-934a-ed2d5177cff3-5177cfde"
            className="label textwhite textright"
          >
            ${item.attributes.price.toFixed(2)}/ HOUR
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Panel;

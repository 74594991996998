// Import Functions.
import React from "react";
import Page from "../components/Page";
import { Link } from "react-router-dom";

function ErrorPage() {
  return (
    <Page data-wf-page="638e032b1539181f025d62a5" data-wf-site="6384e1d3e0ae24517f172c0b">
      <div className="content contentcenter">
        <div className="section wf-section">
          <div className="containervflex aligncenter w-container">
            <h1 className="textcenter">Oops, we seem to be missing a page!</h1>
            <Link to="/" className="button dropshadow w-inline-block">
              <div>HOME</div>
            </Link>
          </div>
        </div>
      </div>

    </Page>
  );
}

export default ErrorPage;

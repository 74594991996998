import React, { useState } from "react";
import { useSelector } from "react-redux";
import AppleStoreButton from "../components/AppleStoreButton";
import GooglePlayButton from "../components/GooglePlayButton";
import Page from "../components/Page";
import Preloader from "../components/Preloader";
import PromotionItem from "../components/PromotionItem";

const ChallengePage = () => {
  // access our data so that we can display it
  const singleTypeSlug = "promotion";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;

  const [isOpen, setIsOpen] = useState();
  // Only display if data has been loaded.

  console.log(cmsData);
  if (!cmsData) return <Preloader />;

  return (
    <Page hideNav data-wf-page="63921988cc6ee0688a06532b" data-wf-site="639218492171acb7aa7f010f">
      <div
        className="section-2 wf-section"
        style={{ backgroundImage: `url(${cmsData?.heroBackground?.data?.attributes?.url}), linear-gradient(267deg, #0d0f15 40%, #3d3d3d 60%, #3d3d3d 80%, #000 90%)` }}
      >
        <div className="div-block-3">{cmsData?.logo?.data?.attributes?.url && <img src={cmsData?.logo?.data?.attributes?.url} loading="lazy" alt="" className="image-2" />}</div>
        <div className="div-block-2">
          <div className="text-block-2">{cmsData.heroText1}</div>
          <div className="text-block">
            {cmsData.heroText2}
            <br />
            {cmsData.heroText3}
            <br />
            {cmsData.heroText4}
          </div>
          <div className="text-block-3">{cmsData.heroText5}</div>
        </div>
      </div>
      <div className="section-5 wf-section">
        <div className="container-4 w-container">
          <h2 className="heading">
            {cmsData?.section2_heading?.split(" ")?.map((el) => {
              if (el[0] === "~") return <span className="glow">{`${el.slice(1)} `}</span>;
              return `${el} `;
            })}
          </h2>
          <div className="text-block-5">{cmsData?.section2_subheading}</div>
          <p className="paragraph">{cmsData?.section2_paragraph}</p>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "60%", flexWrap: "wrap" }}>
            <AppleStoreButton small />
            <GooglePlayButton small />
          </div>
        </div>
      </div>
      {cmsData?.section3_bannerImg?.data?.attributes?.url && (
        <div className="wf-section">
          <img src={cmsData?.section3_bannerImg?.data?.attributes?.url} loading="lazy" alt="" />
        </div>
      )}
      <div className="section-3 wf-section">
        <div className="container-3 w-container">
          <h2 className="heading">
            {cmsData?.section4_heading?.split(" ")?.map((el) => {
              if (el[0] === "~") return <span className="glow">{`${el.slice(1)} `}</span>;
              return `${el} `;
            })}
          </h2>
          {cmsData?.listItems?.map((el) => {
            return <PromotionItem data={el} isOpen={isOpen} setIsOpen={setIsOpen} />;
          })}
        </div>
      </div>
      <div className="section-4 wf-section">
        <div className="w-container">
          <h2 className="heading lbd-centered">
            {cmsData?.section5_heading?.split(" ")?.map((el) => {
              if (el[0] === "~") return <span className="glow">{`${el.slice(1)} `}</span>;
              return `${el} `;
            })}
          </h2>
          <div className="div-block-4">
            {cmsData?.section5_image1?.data?.attributes?.url && <img src={cmsData?.section5_image1?.data?.attributes?.url} loading="lazy" alt="" className="image-3" />}
            {cmsData?.section5_image2?.data?.attributes?.url && <img src={cmsData?.section5_image2?.data?.attributes?.url} loading="lazy" alt="" className="image-3" />}
          </div>
        </div>
      </div>
      <div className="section-6 wf-section">
        <div className="container-5 w-container" style={{ backgroundImage: `url(${cmsData?.pricing_backgroundImg?.data?.attributes?.url})` }}>
          <div className="w-layout-grid grid">
            {cmsData?.pricing?.map((el) => {
              return (
                <div className="priceblock">
                  <div className="priceheading">
                    ‍<strong>{el.heading}</strong>
                  </div>
                  <div className="priceprice">
                    <span className="glow big"> {el.price}</span> {el.plusTax && "+tax"}
                  </div>
                  <div className="pricesubtext">{el.subText}</div>
                </div>
              );
            })}
          </div>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "30%", flexWrap: "wrap" }}>
            <AppleStoreButton small />
            <GooglePlayButton small />
          </div>
          {cmsData?.pricing_logo?.data?.attributes?.url && <img src={cmsData?.pricing_logo?.data?.attributes?.url} loading="lazy" alt="" className="image-5" />}
        </div>
      </div>
    </Page>
  );
};

export default ChallengePage;

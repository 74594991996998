// Import
import React, { useState } from "react";
import Page from "../components/Page";
import { useSelector } from "react-redux";
import "../css/Modal.css";
// Import for Display
import Preloader from "../components/Preloader";

function VideoPage() {
  const singleTypeSlug = "video-page";
  const { data } = useSelector((state) => state.cms);
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;
  const [modalData, setModalData] = useState();
  const [modalShow, setModalShow] = useState(false);

  const handleShowModal = (data) => {
    setModalShow(true);
    setModalData(data);
  };
  const handleHideModal = () => {
    setModalShow(false);
    setModalData();
  };
  // Only load page if data has been retrieved.
  if (!cmsData) return <Preloader />;

  return (
    <Page data-wf-page="638f994ab0904e3d1e2252b3" data-wf-site="6384e1d3e0ae24517f172c0b">
      <div className="content">
        <div className="section wf-section">
          <div className="container w-container">
            {/* Title */}
            <div>
              <h1 className="textcenter">{cmsData.title}</h1>
              <div className="label textcenter">{cmsData.titleDeck}</div>
            </div>
          </div>
        </div>

        {cmsData?.videos?.map((el) => {
          return <VideoCard data={el} showModal={handleShowModal} />;
        })}
      </div>
      <Modal show={modalShow} data={modalData} hide={handleHideModal} />
    </Page>
  );
}

export default VideoPage;

const VideoCard = ({ data, showModal }) => {
  console.log(data);
  if (!data) return null;
  return (
    <div className="divvflex" style={{ width: "80%" }} onClick={() => showModal(data)}>
      <div
        className="panelstyling w-inline-block"
        style={{
          backgroundImage: `linear-gradient(45deg, rgba(0, 0, 0, 0.5), rgba(126, 217, 86, 0.5)), linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${data.coverPhoto?.data?.attributes?.url})`,
        }}
      >
        <div className="w-layout-grid gridpanellisting">
          {/* Service Type */}
          <div id="w-node-_51c61675-59af-b3fe-934a-ed2d5177cfe0-5177cfde" className="textwhite textallcaps">
            {data.title}
          </div>

          <div id="w-node-_51c61675-59af-b3fe-934a-ed2d5177cff1-5177cfde" className="label textwhite ">
            {data.comments}
          </div>
        </div>
      </div>
    </div>
  );
};

const Modal = ({ data, show, hide }) => {
  if (!show || !data) return null;
  return (
    <div className="modal" onClick={hide}>
      <img onClick={hide} class="x" src="images/x-symbol.svg" loading="lazy" alt="" />

      <video onClick={(e) => e.stopPropagation()} class="video" controls>
        <source src={data?.video?.data?.attributes?.url} type={data?.video?.data?.attributes?.mime} />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

// Import Functions.
import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import "../css/Accordion.css";

const FAQ = ({ data }) => {
  const [lastClicked, setLastClicked] = useState(null);

  if (!data) return null;
  return (
    <>
      {data.map((el) => {
        return (
          <AccordionItem
            key={uuid()}
            header={el.attributes.header}
            body={el.attributes.body}
            id={el.id}
            lastClicked={lastClicked}
            setLastClicked={setLastClicked}
          />
        );
      })}
    </>
  );
};

const AccordionItem = ({ header, body, id, lastClicked, setLastClicked }) => {
  const handleClick = () => {
    if (lastClicked === id) {
      setLastClicked(null);
    } else {
      setLastClicked(id);
    }
  };

  return (
    <div
      className="dropdownfaq"
      style={
        lastClicked === id
          ? { zIndex: 901, cursor: "pointer" }
          : { cursor: "pointer" }
      }
      onClick={handleClick}
    >
      <div
        data-w-id="15ddc94c-5f50-26c4-7aea-720678b79d97"
        className={`divfaq  ${lastClicked === id ? "w--open" : null}`}
      >
        <h4 className="textbold"> {header} </h4>

        {/* Plus Minus */}
        <div
          className="dropdowndiv"
          style={{ marginTop: "auto", marginBottom: "auto" }}
        >
          <div className="w-embed">
            <svg
              style={
                lastClicked !== id ? { display: "block" } : { display: "none" }
              }
              width={20}
              height={20}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.1667 9.16667H10.8333V0.833333C10.8333 0.61232 10.7455 0.400358 10.5893 0.244078C10.433 0.0877974 10.221 0 10 0C9.77899 0 9.56702 0.0877974 9.41074 0.244078C9.25446 0.400358 9.16667 0.61232 9.16667 0.833333V9.16667H0.833333C0.61232 9.16667 0.400358 9.25446 0.244078 9.41074C0.0877974 9.56702 0 9.77899 0 10C0 10.221 0.0877974 10.433 0.244078 10.5893C0.400358 10.7455 0.61232 10.8333 0.833333 10.8333H9.16667V19.1667C9.16667 19.3877 9.25446 19.5996 9.41074 19.7559C9.56702 19.9122 9.77899 20 10 20C10.221 20 10.433 19.9122 10.5893 19.7559C10.7455 19.5996 10.8333 19.3877 10.8333 19.1667V10.8333H19.1667C19.3877 10.8333 19.5996 10.7455 19.7559 10.5893C19.9122 10.433 20 10.221 20 10C20 9.77899 19.9122 9.56702 19.7559 9.41074C19.5996 9.25446 19.3877 9.16667 19.1667 9.16667Z"
                fill="black"
                fillOpacity="0.6"
              />
            </svg>
          </div>

          <div className="w-embed">
            <svg
              style={
                lastClicked === id ? { display: "block" } : { display: "none" }
              }
              width={20}
              height={3}
              viewBox="0 0 20 3"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.8333 0.5H2.16667C1.24619 0.5 0.5 0.947715 0.5 1.5C0.5 2.05228 1.24619 2.5 2.16667 2.5H18.8333C19.7538 2.5 20.5 2.05228 20.5 1.5C20.5 0.947715 19.7538 0.5 18.8333 0.5Z"
                fill="black"
                fillOpacity="0.6"
              />
              
            </svg>
          </div>
        </div>
      </div>

      {/* Paragraph */}
      <p
        style={
          lastClicked === id
            ? { display: "block", textAlign: "left", opacity: 1 }
            : { display: "block", opacity: 0, height: 0 }
        }
      >
        {body}
      </p>
    </div>
  );
};

export default FAQ;

// Import Functions.
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { useInterval } from "../hooks/useInterval";

const PanelSlider = ({ data, autoplay }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [touched, setTouched] = useState(false);

  const handleSlideChange = (val) => {
    let tmp = activeSlide + val;
    if (tmp > data.length - 1) {
      tmp = 0;
    } else if (tmp < 0) {
      tmp = data.length - 1;
    }
    setActiveSlide(tmp);
  };

  useInterval(() => {
    if (!touched) handleSlideChange(1);
  }, autoplay);

  if (!data) return null;
  return (
    <div className="slider w-slider" onClick={() => setTouched(true)}>
      <div className="panelmask w-slider-mask">
        {data.map((el, i) => {
          return <Slide key={uuid()} index={i} data={el} activeSlide={activeSlide} handleSlideChange={handleSlideChange} />;
        })}
      </div>
    </div>
  );
};

export default PanelSlider;

const Slide = ({ data, index, activeSlide, handleSlideChange }) => {
  return (
    <div className={`slide w-slide ${activeSlide === index ? "active" : null}`}>
      <Link to={data?.attributes?.slug} className="panelstyling joinpanel w-inline-block">
        <div className="divpaneltext">
          <h3 className="paneljointext">{data.attributes.name}</h3>
          <div className="label textwhite">{data.attributes.nameDeck}</div>
        </div>
        <img src={data?.attributes?.image?.data?.attributes?.url} loading="lazy" alt="" className="paneljoinimage" />
        <div className="paneloverlay" />

        <div
          className="sliderarrowleft w-slider-arrow-left"
          onClick={(e) => {
            e.preventDefault();
            handleSlideChange(-1);
          }}
        >
          <div className="w-icon-slider-left" />
        </div>
        <div
          className="sliderarrowright w-slider-arrow-right"
          onClick={(e) => {
            e.preventDefault();
            handleSlideChange(1);
          }}
        >
          <div className="w-icon-slider-right" />
        </div>
      </Link>
    </div>
  );
};

import { useNavigate } from "react-router-dom";

export const usePullEntry = (collectionSlug, data, id) => {
  const navigate = useNavigate();
  try {
    return data[collectionSlug] && data[collectionSlug].filter((el) => el.id == id)[0].attributes;
  } catch (error) {
    navigate("/404");
    return null;
  }
};

import React from "react";

function Preloader() {
  return (
    <div
      data-w-id="d29b27c8-22cd-9889-031c-a11c1f356afd"
      style={{ display: "flex" }}
      className="preloader"
    >
      <div
        data-w-id="6653b886-8ac5-0a70-eeff-2bab1f28fcb4"
        data-animation-type="lottie"
        data-src="documents/Moxie-Project.json"
        data-loop={0}
        data-direction={1}
        data-autoplay={1}
        data-is-ix2-target={0}
        data-renderer="svg"
        data-default-duration="5.033668726910009"
        data-duration={0}
      />
    </div>
  );
}
export default Preloader;

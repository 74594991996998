// Import Functions.
import React from "react";
import Page from "../components/Page";
import { useSelector } from "react-redux";
import { useSubmitForm } from "../hooks/useSubmitForm";

// Import functions for display.
import Preloader from "../components/Preloader";

function ContactPage() {
  const singleTypeSlug = "contact";
  const { data } = useSelector((state) => state.cms);

  // Call cmsData.
  const cmsData = data[singleTypeSlug] && data[singleTypeSlug].attributes;
  const { handleSubmit } = useSubmitForm();

  // Continue preloading animation until data is retrieved.
  if (!cmsData) return <Preloader />;
  return (
    <Page>
      <div
        className="content contentcenter custombackground"
        style={{
          backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../images/Concrete_1.jpg')`,
        }}
      >
        <div className="section wf-section">
          <div className="containervflex alignright w-container">
            <div className="w-layout-grid gridcontact">
              <div
                id="w-node-_4b3d3d19-bc6c-7e4a-b7f1-8d9ee031d1b9-266310bd"
                className="textcentermobile"
              >
                {/* Title */}
                <h1 className="textwhite">
                  <span className="greentext"> Get in Touch </span>
                </h1>
                <div className="label textwhite">Connect With Moxie</div>
              </div>

              {/* Image A */}
              <img
                src={cmsData.imageA.data && cmsData.imageA.data.attributes.url}
                loading="lazy"
                id="w-node-cbf62936-ee63-2e34-73df-b02fa885ba04-266310bd"
                alt=""
                className="imagecontact"
              />

              {/* Image B */}
              <img
                src={cmsData.imageB.data && cmsData.imageB.data.attributes.url}
                loading="lazy"
                width={1024}
                id="w-node-cbf62936-ee63-2e34-73df-b02fa885ba02-266310bd"
                alt=""
                className="imagecontact"
              />

              <div id="w-node-fa1ffc87-f422-006f-9ddd-d47c7128a6aa-266310bd">
                <div className="formcontact w-form">
                  <form
                    onSubmit={handleSubmit}
                    id="contact-form"
                    name="email-form"
                    data-name="Email Form"
                    method="get"
                    className="formcontact divvflex"
                    style={{
                      alignItems: "flex-end",
                      justifyContent: "space-between",
                      gridRowGap: "0px",
                    }}
                  >
                    {/* Name */}
                    <input
                      type="text"
                      className="w-input"
                      maxLength={256}
                      name="Name"
                      data-name="Name"
                      placeholder="Full Name*"
                      id="name"
                      required=""
                    />

                    {/* Email */}
                    <input
                      type="email"
                      className="w-input"
                      maxLength={256}
                      name="Email"
                      data-name="Email"
                      placeholder="Email*"
                      id="email"
                      required=""
                    />

                    {/* Message */}
                    <textarea
                      type="text"
                      className="messagefield w-input"
                      maxLength={5000}
                      name="Message"
                      data-name="message"
                      placeholder="Your message*"
                      id="message"
                      required=""
                    />
                    <div id="w-node-_5410a1b4-3e07-2179-2cf8-9b87e30bffac-266310bd">
                      <input
                        type="submit"
                        data-wait="PLEASE WAIT..."
                        defaultValue="SUBMIT"
                        className="button dropshadow w-inline-block"
                        style ={{marginTop:"16px"}}
                      />
                    </div>
                  </form>
                </div>
              </div>

              {/* Image C */}
              <img
                src={cmsData.imageC.data && cmsData.imageC.data.attributes.url}
                loading="lazy"
                id="w-node-cbf62936-ee63-2e34-73df-b02fa885ba03-266310bd"
                alt="pushups"
                className="imagecontact"
              />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default ContactPage;

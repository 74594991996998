// Import
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { usePullEntry } from "../hooks/usePullEntry";
import React from "react";
import Page from "../components/Page";

// Import for Display
import AppleStoreButton from "../components/AppleStoreButton";
import GooglePlayButton from "../components/GooglePlayButton";
import DayListing from "../components/DayListing";
import Preloader from "../components/Preloader";

function ListingPage() {
  // Access our data so that we can display it
  const { data } = useSelector((state) => state.cms);

  // Access data for this specific entry.
  let { id } = useParams();
  const collectionSlug = "listings";

  const entryData = usePullEntry(collectionSlug, data, id);

  // Only display if content has been loaded.

  if (!entryData) return <Preloader />;
  document.title = entryData.name;
  return (
    <Page
      data-wf-page="6384e2aa759e4d28f36f8833"
      data-wf-site="6384e1d3e0ae24517f172c0b"
    >
      <div className="content contentcenter">
        <div className="section wf-section">
          <div className="containervflex alignright w-container">
            <div className="divhflex divlistingdetails">
              <img
                src={
                  entryData.image.data && entryData.image.data.attributes.url
                }
                loading="lazy"
                alt=""
                className="imagelisting rounding"
              />
              <div className="divvflex">
                <h1 className="textcentermobile">{entryData.name}</h1>
                {/* Service Type */}
                <div className="label textcentermobile">
                  {entryData.serviceType}
                </div>

                {/* Service Price */}
                <div className="label textcentermobile">
                  ${entryData.price.toFixed(2)} / HOUR
                </div>

                {/*Day Availability */}
                <div className="label divavailabilitytext">
                  {DayListing(entryData.sunday, "S", "G")}
                  {DayListing(entryData.monday, "M", "G")}
                  {DayListing(entryData.tuesday, "T", "G")}
                  {DayListing(entryData.wednesday, "W", "G")}
                  {DayListing(entryData.thursday, "T", "G")}
                  {DayListing(entryData.friday, "F", "G")}
                  {DayListing(entryData.saturday, "S", "G")}
                </div>

                {/* Description */}
                <p>{entryData.richText}‍</p>
              </div>

              {/* Button to GloFox */}
            </div>

            <div className="divhtov listing">
              <AppleStoreButton />
              <GooglePlayButton />
              {entryData.buttonListing && (
                <a
                  href={entryData.buttonListing.slug
                  }
                  className="buttonlanding w-inline-block"
                >
                  <div>
                    {entryData.buttonListing.text}
                  </div>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default ListingPage;
